import { type ReactNode, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { OverlayTriggerState } from 'react-stately';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { useCareProvider } from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { TaskCard } from '../TaskCard';
import type { Task } from '../types';
import { TaskGroup } from './TaskGroup';
import { container } from './TaskList.css';
import { TaskListContextProvider } from './taskListContext';
import type { ParentView } from './types';

type Props = {
  tasks: Task[];
  parentView: ParentView;
  taskHubOverlayState?: Nullable<OverlayTriggerState>;
  classes?: {
    container?: string;
  };
  TaskCardComponent?: (props: {
    task: Task;
    parentView: ParentView;
    isActive?: boolean;
    onOpenTaskDetail?: (task: Task) => void;
  }) => JSX.Element;
  isCompact?: boolean;
  activeTaskId?: string;
  onOpenTaskDetail?: (task: Task) => void;
  children?: ReactNode;
  bucketFunction?: (
    tasks: Task[],
  ) => Record<'ownPatient' | 'urgent' | 'rest', Task[]>;
};

export function TaskList({
  tasks,
  parentView,
  taskHubOverlayState,
  classes,
  TaskCardComponent = TaskCard,
  isCompact = false,
  activeTaskId,
  onOpenTaskDetail,
  children,
  bucketFunction = (taskList) => ({
    rest: taskList,
    ownPatient: [],
    urgent: [],
  }),
}: Props) {
  const intl = useIntl();
  const { currentUserId } = useCurrentUser();
  const { toaster } = useToaster();
  const {
    isLoading,
    error,
    data: loggedInProvider,
  } = useCareProvider(currentUserId);
  useEffect(() => {
    if (error) {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to fetch logged in provider information',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const bucketed = useMemo(
    () => bucketFunction(tasks),
    [bucketFunction, tasks],
  );

  if (isLoading || !loggedInProvider) {
    return <LoadingPlaceholder isLoading />;
  }

  return (
    <TaskListContextProvider
      value={{
        loggedInProvider,
        parentView,
        taskHubOverlayState: taskHubOverlayState || null,
      }}
    >
      <div className={classes?.container || container}>
        {bucketed.urgent.length > 0 && (
          <TaskGroup
            tasks={bucketed.urgent}
            title={<FormattedMessage defaultMessage="Needs attention" />}
            TaskCardComponent={TaskCardComponent}
            parentView={parentView}
            activeTaskId={activeTaskId}
            isCompact={isCompact}
            onOpenTaskDetail={onOpenTaskDetail}
          />
        )}
        {bucketed.ownPatient.length > 0 && (
          <TaskGroup
            tasks={bucketed.ownPatient}
            title={<FormattedMessage defaultMessage="My patients" />}
            badgeVariant="muted"
            TaskCardComponent={TaskCardComponent}
            parentView={parentView}
            activeTaskId={activeTaskId}
            isCompact={isCompact}
            onOpenTaskDetail={onOpenTaskDetail}
          />
        )}
        {bucketed.rest.length > 0 && (
          <TaskGroup
            tasks={bucketed.rest}
            // we only care about displaying a title if we have
            // things in our other buckets. otherwise it looks weird
            // to just have a list of "Other patients" tasks
            title={
              bucketed.urgent.length || bucketed.ownPatient.length ? (
                <FormattedMessage defaultMessage="Other patients" />
              ) : undefined
            }
            badgeVariant="muted"
            TaskCardComponent={TaskCardComponent}
            parentView={parentView}
            activeTaskId={activeTaskId}
            isCompact={isCompact}
            onOpenTaskDetail={onOpenTaskDetail}
          />
        )}
        {children}
      </div>
    </TaskListContextProvider>
  );
}
